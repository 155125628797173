<template>
    <div id="pageList" class="DriverList">
        <ListEngine :pageList="pageList" ref="pageListRef">
            <template v-slot:queryParams>
                <el-row class="myRow">
                    <el-col :span="3" class="myColTitle">司机名称：</el-col>
                    <el-col :span="9">
                        <el-input @input="e => pageList.queryParam.name = validForbid(e)" placeholder="请输入司机名称进行模糊匹配查询..." v-model="pageList.queryParam.name" clearable/>
                    </el-col>
                    <el-col :span="3" class="myColTitle">所属承运商：</el-col>
                    <el-col :span="9">
                        <el-input @input="e => pageList.queryParam.cysId = validForbid(e)" placeholder="请输入承运商进行模糊匹配查询..." v-model="pageList.queryParam.cysId" clearable/>
                    </el-col>
                </el-row>
                <el-row class="myRow">
                    <el-col :span="3" class="myColTitle">关联车队：</el-col>
                    <el-col :span="9">
                        <el-input @input="e => pageList.queryParam.fleetName = validForbid(e)" placeholder="请输入车队进行模糊匹配查询..." v-model="pageList.queryParam.fleetName" clearable/>
                    </el-col>
                    <el-col :span="3" class="myColTitle">所在城市：</el-col>
                    <el-col :span="9">
                        <el-select :ref="el=>refMap.set('cityId',el)" v-model="pageList.queryParam.cityId" placeholder="请选择所在城市" clearable style="width:100%">
                            <el-option v-for="item in comboSelect({comboId:'cityId'})" :key="item.value" :label="item.label" :value="item.value"/>
                        </el-select>
                    </el-col>
                </el-row>
                <el-row class="myRow">
                    <el-col :span="3" class="myColTitle">准驾车型：</el-col>
                    <el-col :span="9">
                        <el-select :ref="el=>refMap.set('zjcxType',el)" v-model="pageList.queryParam.zjcxType" placeholder="请选择准驾车型" clearable style="width:100%">
                            <el-option v-for="item in comboSelect({comboId:'zjcxType'})" :key="item.value" :label="item.label" :value="item.value"/>
                        </el-select>
                    </el-col>
                    <el-col :span="3" class="myColTitle">结算主体：</el-col>
                    <el-col :span="9">
                        <el-select :ref="el=>refMap.set('jsztType',el)" v-model="pageList.queryParam.jsztType" placeholder="请选择结算主体" clearable style="width:100%">
                            <el-option v-for="item in comboSelect({comboId:'jsztType'})" :key="item.value" :label="item.label" :value="item.value"/>
                        </el-select>
                    </el-col>
                </el-row>
                <el-row class="myRow">
                    <el-col :span="3" class="myColTitle">注册手机：</el-col>
                    <el-col :span="9">
                        <el-input @input="e => pageList.queryParam.phone = validForbid(e)" placeholder="请输入注册手机进行模糊匹配查询..." v-model="pageList.queryParam.phone" clearable/>
                    </el-col>
                    <el-col :span="3" class="myColTitle">身份证号：</el-col>
                    <el-col :span="9">
                        <el-input @input="e => pageList.queryParam.sfz = validForbid(e)" placeholder="请输入身份证号进行模糊匹配查询..." v-model="pageList.queryParam.sfz" clearable/>
                    </el-col>
                </el-row>
                <el-row class="myRow">
                    <el-col :span="3" class="myColTitle">卡号：</el-col>
                    <el-col :span="9">
                        <el-input @input="e => pageList.queryParam.cardNum = validForbid(e)" placeholder="请输入卡号进行模糊匹配查询..." v-model="pageList.queryParam.cardNum" clearable/>
                    </el-col>
                    <el-col :span="3" class="myColTitle">开户行：</el-col>
                    <el-col :span="9">
                        <el-input @input="e => pageList.queryParam.khh = validForbid(e)" placeholder="请输入开户行进行模糊匹配查询..." v-model="pageList.queryParam.khh" clearable/>
                    </el-col>
                </el-row>
                <el-row class="myRow">
                    <el-col :span="3" class="myColTitle">审核状态：</el-col>
                    <el-col :span="9">
                        <el-select :ref="el=>refMap.set('status',el)" v-model="pageList.queryParam.status" placeholder="请选择审核状态" clearable style="width:100%">
                            <el-option v-for="item in comboSelect({comboId:'status'})" :key="item.value" :label="item.label" :value="item.value"/>
                        </el-select>
                    </el-col>
                    <el-col :span="3" class="myColTitle">是否停用：</el-col>
                    <el-col :span="9">
                        <el-select :ref="el=>refMap.set('flag',el)" v-model="pageList.queryParam.flag" placeholder="请选择是否停用" clearable style="width:100%">
                            <el-option v-for="item in comboSelect({comboId:'flag'})" :key="item.value" :label="item.label" :value="item.value"/>
                        </el-select>

<!--                        <el-select v-model="value" placeholder="请选择" v-selectLoadMore="loadmore">-->
<!--                            <el-option v-for="item in options" :key="item.id" :label="item.label" :value="item.id"/>-->
<!--                        </el-select>-->
                    </el-col>
                </el-row>
                <el-row class="myRow">
                    <el-col :span="3" class="myColTitle">司机类别：</el-col>
                    <el-col :span="9">
                        <el-select :ref="el=>refMap.set('type',el)" v-model="pageList.queryParam.type" clearable placeholder="请选择司机类别" style="width: 100%" :disabled="disabled" @change="(val)=>{selectOnChange(val,'type')}">
                            <el-option v-for="item in comboSelect({comboId:'type'})" :key="item.value" :label="item.label" :value="item.value"/>
                        </el-select>
                    </el-col>
                </el-row>
            </template>
            <!--重写表格固定列-->
            <template v-slot:fixCol v-if="engineParams">
                <el-table-column fixed="left" header-align="center" align="center" label="操作" width="150">
                    <template #default="scope">
                        <el-button @click="pageListRef.editHandler(scope.row)" type="text">
                            <i class="el-icon-edit"></i>&nbsp;&nbsp;详情
                        </el-button>
                    </template>
                </el-table-column>
            </template>
            <template v-slot:tbCols>
                <el-table-column prop="F_NAME" label="司机名称"/>
                <el-table-column prop="F_TYPE" label="司机类别"/>
                <el-table-column prop="F_CYS_ID" label="所属承运商"/>
                <el-table-column prop="F_FLEET_ID" label="关联车队"/>
                <el-table-column prop="F_CITY_ID" label="所在城市"/>
                <el-table-column prop="F_ZJCX_TYPE" label="准驾车型"/>
                <el-table-column prop="F_JSZT_TYPE" label="结算主体"/>
                <el-table-column prop="F_PHONE" label="注册手机"/>
                <el-table-column prop="F_SFZ" label="身份证号"/>
                <el-table-column prop="F_CARD_NUM" label="卡号"/>
                <el-table-column prop="F_KHH" label="开户行"/>
                <el-table-column prop="F_STATUS" label="审核状态"/>
                <el-table-column prop="F_FLAG" label="是否停用"/>
                <el-table-column prop="F_TIME" label="创建时间"/>
            </template>
        </ListEngine>
    </div>
</template>

<script>
    import DriverCard from './DriverCard';
    import {ref, reactive, toRefs, computed, defineComponent, onMounted, getCurrentInstance, watch, provide} from 'vue';
    export default defineComponent ({
        name: "DriverList",
        props: {
            engineParams: {
                type: Object
            }
        },
        setup(props,context){
            const {proxy}=getCurrentInstance();
            const utils=proxy.utils;
            if(props.engineParams)console.log(props.engineParams.from);
            let dataObj=reactive({
                value: '',
                options: [],
                formData: {
                    pageIndex: 1,
                    pageSize: 20,
                },



                pageListRef:null,
                refMap:new Map(),
                cityData:[],
                carId:props.engineParams?props.engineParams.carId:'',
                pageList: {
                    // isShowFixCol:props.engineParams?false:true,
                    queryParam: {
                       name:"", 
                       sfz:"", 
                       jsztType:"", 
                       zjcxType:"", 
                       khh:"", 
                       cardNum:"", 
                       status:"", 
                       flag:"", 
                       cysId:"",
                        provinceId:"",
                        cityId:"",
                        fleetName:'',
                        fleetId:props.engineParams?props.engineParams.fleetId:'',
                        carId:props.engineParams?props.engineParams.carId:'',//如果是运力配置那里打开车辆列表之后，再车辆列表点击绑定司机的时候，会传入该参数，这个参数将会回传给司机列表
                        phone:"" ,
                        from:props.engineParams?props.engineParams.from:''
                    },
                    modelComp: DriverCard,
                    modelMethod: "/driver/pageData"
               }
            })
            onMounted(async ()=>{
                await buildCityData();
                getList(dataObj.formData);
            })
            const selectOnChange=async(newValue,selectId)=>{

            }
            //---------------------------computed------------
            //下拉选择
            const comboSelect=computed(()=>{
                return (params) => {
                    if(params && 'status'==params.comboId){
                        return [{value:0,label:'待渠道审核'},{value:1,label:'渠道已拒绝'},{value:2,label:'待平台审核'},{value:3,label:'已通过审核'},{value:4,label:'平台已拒绝'}]
                    }
                    if(params && 'flag'==params.comboId){
                        return [{value:0,label:'启用'},{value:1,label:'禁用'}]
                    }
                    if(params && 'type'==params.comboId){
                        return [{value:0,label:'有车司机'},{value:1,label:'无车司机'}]
                    }
                    if(params && 'cityId'==params.comboId){
                        return dataObj.cityData;
                    }
                    if(params && 'jsztType'==params.comboId){
                        return [{value:0,label:'车队'},{value:1,label:'个人'},{value:2,label:'承运商'}]
                    }
                    if(params && 'zjcxType'==params.comboId){
                        return [{value:0,label:'A1'},{value:1,label:'A2'},{value:2,label:'A3'},{value:3,label:'B1'},{value:4,label:'B2'},{value:5,label:'C1'}]
                    }
                }
            })

            const buildCityData=async ()=>{
                dataObj.cityData = await utils.$$api.getCitiesByChannelId({});
            }


            const loadmore=()=>{
                dataObj.formData.pageIndex++;
                dataObj.getList(this.formData);
            }
            const getList=(formData)=>{
                console.log(formData)
                // 这里是接口请求数据, 带分页条件
                const _res = [// 请求得到的数据
                    ];
                for(let i=1;i<20;i++){
                    _res.push({id:i,label:'a'+i})
                }
                dataObj.options = [...dataObj.options, ..._res];
            }
            return{
                ...toRefs(dataObj),comboSelect,selectOnChange,loadmore,getList
            }
        }
    });
</script>

<style scoped>
    .DriverList{
        width: 100%;
    }
</style>